<template>
<div>
  <div class="contain">
    <div style="padding:10px;font-size: 14px;font-weight: bolder">
      状态：
      <el-select clearable  size="mini" style="width:130px;" class="EquipmentMarginLeft" v-model="pageParams.generalStatus" placeholder="菜单状态">
        <el-option
            v-for="item in userStatus"
            :key="item.id"
            :label="item.label"
            :value="item.id">
        </el-option>
      </el-select>
      菜单名称：
      <el-input size="mini" v-model="pageParams.name" style="width:130px;" placeholder="请输入菜单名称" class="EquipmentMarginLeft"></el-input>
      <el-button size="mini" type="primary" style="margin-left:20px;" icon="el-icon-search" @click="search">查询</el-button>
      <el-button type="primary" size="mini" icon="el-icon-refresh" @click="recet">重置</el-button>
      <el-button type="success" size="mini" class="EquipmentMarginLeft"
                 @click="addmenu(0)" icon="el-icon-plus">新增一级菜单
      </el-button>
    </div>
    <el-table
        :data="tableData"
        style="width: 100%;margin: 10px 0;"
        class="table-no-search"
        row-key="id"
        border
        :default-expand-all="false"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        stripe
        :header-cell-style="{color:'#333',fontFamily:'MicrosoftYaHeiUI',fontSize:'14px',fontWeight:900,background:'#f8f8f9'}">

      <el-table-column prop="name" label="菜单名称" width="200"></el-table-column>


      <el-table-column prop="weight" label="排序" align="center" width="80px"></el-table-column>
      <el-table-column prop="url" label="组件名称" align="center" width="130px"></el-table-column>
      <el-table-column prop="router" label="组件路径" align="center"></el-table-column>
      <el-table-column prop="displayMode" label="展示方式" align="center" width="80">
        <template  slot-scope="{row}" >
          <span v-if="row.type==1" >{{row.displayMode==1?'APP':'PC'}}</span>
          <span v-else>按钮</span>
        </template>
        
      </el-table-column>
      <el-table-column prop="code" label="菜单编码" align="center"></el-table-column>
      <el-table-column prop="generalStatus" label="状态" align="center" width="80px">
        <template slot-scope="{row}" >
          <el-switch v-model="row.generalStatus" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0" @change="statusChange($event, row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" align="center" width="170px"></el-table-column>
      <el-table-column prop="remark" label="备注" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" align="left" width="250px">
        <template slot-scope="{row}">
          <el-button type="text" @click="updataUser(row)" size="small">编辑</el-button>
          <el-button v-if="row.type==1" type="text" @click="addmenu(row.id)" size="small" style="color:#67C23A">新增子菜单</el-button>
          <el-button v-if="row.type==1" type="text" @click="addbutton(row,0)" size="small" style="color:#67C23A">新增按钮</el-button>
          <el-button type="text" @click="del(row)" size="small" style="color:red">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <el-dialog
      :title="updatasonStatus==0?'新增子菜单信息':'修改子菜单信息'"
      :visible.sync="sondialogVisible"
      width="35%"
      :close-on-click-modal="false"
      @close="clearnsonF('sonform')">
    <el-form ref="sonform" :model="sonform" label-width="100px" :rules="rule">
      <div style="display: flex">
        <el-form-item label="菜单名称：" prop="name">
          <el-input v-model="sonform.name"></el-input>
        </el-form-item>
        <el-form-item label="菜单排序：" prop="sort">
          <el-input v-model="sonform.sort"  type="number"></el-input>
        </el-form-item>
      </div>
      <div style="display: flex">
        <el-form-item label="菜单编码：" prop="code">
          <el-input v-model="sonform.code" placeholder="编码示例：“xxx:yyy”"></el-input>
        </el-form-item>
        <el-form-item label="菜单描述：">
          <el-input v-model="sonform.description" ></el-input>
        </el-form-item>
      </div>
      <div style="display: flex">
        <el-form-item label="组件名称：" prop="url">
          <el-input v-model="sonform.url"></el-input>
        </el-form-item>
        <el-form-item label="显示方式：" prop="displayMode">
          <el-radio-group v-model="sonform.displayMode">
            <el-radio :label="0">PC</el-radio>
            <el-radio :label="1">APP</el-radio>
          </el-radio-group>
        </el-form-item>

      </div>
      <div style="display: flex">
        <el-form-item label="组件路径：" prop="router">
          <el-input v-model="sonform.router" ></el-input>
        </el-form-item>
        <el-form-item label="菜单状态：" prop="generalStatus">
          <el-radio-group v-model="sonform.generalStatus">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <el-form-item label="备注：">
        <el-input type="textarea" v-model="sonform.remark"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button @click="sondialogVisible = false">取 消</el-button>
    <el-button v-if="updatasonStatus==0" type="primary" @click="submitsonfrom('sonform')">确 定</el-button>
    <el-button v-else type="primary" @click="updatasubmitsonfrom('sonform')">确 定</el-button>
  </span>
  </el-dialog>
  <el-dialog
      :title="updataStatus==0?'新增菜单信息':'修改菜单信息'"
      :visible.sync="dialogVisible"
      width="35%"
      :close-on-click-modal="false"
      @close="clearnF('form')">
    <el-form ref="form" :model="form" label-width="100px" :rules="rule">
      <div style="display: flex">
        <el-form-item label="菜单名称：" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="菜单排序：" prop="sort">
          <el-input v-model="form.sort"  type="number"></el-input>
        </el-form-item>
      </div>
      <div style="display: flex">
        <el-form-item label="菜单编码：" prop="code">
          <el-input v-model="form.code" placeholder="编码示例：“xxx:yyy”"></el-input>
        </el-form-item>
        <el-form-item label="菜单描述：">
          <el-input v-model="form.description" ></el-input>
        </el-form-item>
      </div>
      <div style="display: flex">
        <el-form-item label="组件名称：" prop="url">
          <el-input v-model="form.url"></el-input>
        </el-form-item>
        <el-form-item label="显示方式：" prop="displayMode">
          <el-radio-group v-model="form.displayMode">
            <el-radio :label="0">PC</el-radio>
            <el-radio :label="1">APP</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div style="display: flex">
      <el-form-item label="组件路径：" prop="router">
        <el-input v-model="form.router" ></el-input>
      </el-form-item>
        <el-form-item label="菜单状态：" prop="generalStatus">
          <el-radio-group v-model="form.generalStatus">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <el-form-item label="备注：">
        <el-input type="textarea" v-model="form.remark"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button v-if="updataStatus==0" type="primary" @click="submitfrom('form')">确 定</el-button>
    <el-button v-else type="primary" @click="updatasubmitfrom('form')">确 定</el-button>
  </span>
  </el-dialog>


    <el-dialog
      :title="updatabuttonStatus==0?'新增按钮':'修改按钮'"
      :visible.sync="buttondialogVisble"
      width="35%"
      :close-on-click-modal="false"
      @close="clearnbuttonF('buttonform')">
    <el-form ref="buttonform" :model="buttonform" label-width="100px" :rules="rule">
      <div style="display: flex">
        <el-form-item label="按钮名称：" prop="name">
          <el-input v-model="buttonform.name"></el-input>
        </el-form-item>
      </div>
      <div style="display: flex">
        <el-form-item label="按钮编码：" prop="code">
          <el-input v-model="buttonform.code" placeholder="编码示例：“xxx:yyy”"></el-input>
        </el-form-item>
      </div>
      
      <div style="display: flex">
        <el-form-item label="按钮状态：" prop="generalStatus">
          <el-radio-group v-model="buttonform.generalStatus">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button @click="buttondialogVisble = false">取 消</el-button>
    <el-button v-if="updatabuttonStatus==0" type="primary" @click="submitbuttonfrom('buttonform')">确 定</el-button>
    <el-button v-else type="primary" @click="updatasubmitbuttonfrom('buttonform')">确 定</el-button>
  </span>
  </el-dialog>





</div>
</template>

<script>
import { saveButton } from '../../RequestPort/user/button'
import {selectPermissionTreeList,saveMenu,updateMenu,selectMenuMaxSort,delmenu} from "../../RequestPort/user/menuList"
import tempMaint from '../tempManage/tempMaint.vue'

export default {
  components: { tempMaint },
  name: "menuList",
  data(){
    return{
      sonform:{
        whetherUpdateImg:'n',
        name:'',
        url:'',
        router:'',
        description:'',
        generalStatus:1,
        displayMode:0,
        status:1,
        weight:'',
        sort:0,
        type:1,
      },

      buttonform:{
        name:'',
        code:'',
        pid:'',
        type:2,
        status:1,
        generalStatus:1,
        displayMode:0,
      },


      dialogVisible:false,
      sondialogVisible:false,
      buttondialogVisble:false,
      updataStatus:0,
      updatasonStatus:0,
      updatabuttonStatus:0,
      total:0,
      tableData:[],
      form:{
        whetherUpdateImg:'n',
        menuOpenIcon:'0',
        menuCloseIcon:'0',
        ossKey:'0',
        newOssKey:'0',
        oldOssKey:'0',
        name:'',
        url:'',
        router:'',
        description:'',
        generalStatus:1,
        displayMode:0,
        status:1,
        weight:'',
        sort:0,
        type:1,

      },
      userStatus:[{label:"正常",id:1},{label:"停用",id:0}],
      rule:{
        name: [
          { required: true, message: '请输入菜单名称', trigger: 'blur' }
        ],
        url: [
          { required: true, message: '请输入组件名称', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请填写编码', trigger: 'blur' }
        ],
        router: [
          { required: true, message: '请输入组件路径', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '请输入排序', trigger: 'blur' }
        ],
        generalStatus: [
          { required: true, message: '请选择', trigger: 'blur' }
        ],
        displayMode: [
          { required: true, message: '请选择', trigger: 'blur' }
        ],
      },
      pageParams:{
        name:'',
        generalStatus:'',
        type:1
      }
    }
  },
  mounted() {
    this.loadList(this.pageParams)
  },
  methods:{
    statusChange(val, row) {
      const statusText = val === 0 ? '禁用' : '开启'
      this.$confirm(`是否${ statusText }此菜单?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).then(() => {
        row.generalStatus = val
        row.permissionId = row.id
        row.sort = row.weight
        row.pid = row.parentId
        updateMenu(row).then((res)=>{
          if(res.code===200){
            // 新增成功之后清除
            this.loadList(this.pageParams)
            this.$message.success(res.msg)
          }else {
            this.$message.error(res.msg)
          }
        })

      }).catch(() => {
        row.generalStatus ==0?row.generalStatus=1:row.generalStatus =0
      })
    },
    del(row){
      this.$confirm(`是否删除菜单---[ ${row.name} ]?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).then(() => {
        delmenu({permissionId:row.id,ossKey: row.ossKey}).then((e)=>{
          if(e.code===200){
            this.$message.success(e.msg)
           this.loadList(this.pageParams)
          }else{
            this.$message.error(e.msg)
          }
        })
      })
    },
    submitfrom(form){
      this.form.userId = localStorage.getItem('userId')
      this.$refs[form].validate((valid) => {
        if (!valid) return false
        saveMenu(this.form).then((res)=>{
          if(res.code===200){
            this.dialogVisible=!this.dialogVisible
            this.loadList(this.pageParams)
            this.$message.success(res.msg)
          }else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    updatasubmitfrom(form){
      this.$refs[form].validate((valid) => {
        if (!valid) return false
          updateMenu(this.form).then((res)=>{
            if(res.code===200){
              this.dialogVisible=!this.dialogVisible
              this.loadList(this.pageParams)
              this.$message.success(res.msg)
            }else {
              this.$message.error(res.msg)
            }
          })
      })
    },

    submitbuttonfrom(form){
        this.buttonform.userId = localStorage.getItem('userId')
        this.$refs[form].validate((valid) => {
        if (!valid) return false
          saveMenu(this.buttonform).then((res)=>{
            if(res.code===200){
              this.buttondialogVisble=!this.buttondialogVisble
              this.loadList(this.pageParams)
              this.$message.success(res.msg)
            }else {
              this.$message.error(res.msg)
            }
          })
      })
    },
   updatasubmitbuttonfrom (form){
      this.$refs[form].validate((valid) => {
        if (!valid) return false
          updateMenu(this.buttonform).then((res)=>{
            if(res.code===200){
              this.buttondialogVisble=!this.buttondialogVisble
              this.loadList(this.pageParams)
              this.$message.success(res.msg)
            }else {
              this.$message.error(res.msg)
            }
          })
      })
    },

    submitsonfrom(form){
      this.sonform.userId = localStorage.getItem('userId')
      this.$refs[form].validate((valid) => {
        if (!valid) return false
        saveMenu(this.sonform).then((res)=>{
          if(res.code===200){
            this.sondialogVisible=!this.sondialogVisible
            this.loadList(this.pageParams)
            this.$message.success(res.msg)
          }else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    updatasubmitsonfrom(form){
      this.$refs[form].validate((valid) => {
            if (!valid) return false
          updateMenu(this.sonform).then((res)=>{
            if(res.code===200){
              // 新增成功之后清除
              this.sondialogVisible=!this.sondialogVisible
              this.loadList(this.pageParams)
              this.$message.success(res.msg)
            }else {
              this.$message.error(res.msg)
            }
          })
      })
    },
    updataUser(row){
      if(row.parentId==0){
        this.updataStatus = 1
        let obj = JSON.parse(JSON.stringify(row))
        this.form = obj
        this.form.permissionId = row.id
        this.$set(this.form,'sort',row.weight)
        this.form.pid = row.parentId
        this.dialogVisible = !this.dialogVisible
      }else {
        if(row.type==1){
          this.sondialogVisible = !this.sondialogVisible
           this.updatasonStatus = 1
          let obj = JSON.parse(JSON.stringify(row))
          this.sonform = obj
          this.sonform.permissionId = row.id
          this.sonform.pid = row.parentId
          this.$set(this.sonform,'sort',row.weight)
        }else{
          this.buttondialogVisble = !this.buttondialogVisble      
          this.updatabuttonStatus = 1   
          let obj = JSON.parse(JSON.stringify(row))
          this.buttonform = obj
          this.buttonform.permissionId = row.id
          this.buttonform.pid = row.parentId
          this.$set(this.buttonform,'sort',row.weight)
        }
       
      }
    },
    handlePreview(file) {
      console.log(file);
    },
    addbutton(row,type){
        this.buttonform.pid = row.id
        this.buttonform.displayMode = row.displayMode
        this.updatabuttonStatus = type
        this.buttondialogVisble = !this.buttondialogVisble
        selectMenuMaxSort().then((res)=>{
          this.form.sort = res.data
          this.$forceUpdate()
        })
    },
    addmenu(id){
      if(id==0){
        this.form.pid = id
        this.updataStatus = 0
        this.dialogVisible = !this.dialogVisible
        selectMenuMaxSort().then((res)=>{
          this.form.sort = res.data
          this.$forceUpdate()
        })
      }else {
        this.sonform.pid = id
        this.updatasonStatus = 0
        this.sondialogVisible = !this.sondialogVisible
        selectMenuMaxSort().then((res)=>{
          this.sonform.sort = res.data
          this.$forceUpdate()
        })
      }
    },
    addsonmenu(id){
      this.sonform.pid = id
    },
    loadList(obj){
      selectPermissionTreeList(obj).then((res)=>{
        this.tableData = res.data
      })
    },
    recet(){
      this.pageParams={
        name:'',
        generalStatus:'',
        type:1
      }
      this.loadList(this.pageParams)
    },
    clearnF(form){
      this.form={
        whetherUpdateImg:'n',
        menuOpenIcon:'0',
        menuCloseIcon:'0',
        ossKey:'0',
        newOssKey:'0',
        oldOssKey:'0',
        name:'',
        url:'',
        router:'',
        description:'',
        generalStatus:1,
        displayMode:0,
        status:1,
        sort:0,
        type:1,
      }
      this.$refs[form].resetFields()
    },

    clearnbuttonF(form){
        this.buttonform={
        name:'',
        code:'',
        pid:'',
        type:2,
        status:1,
        generalStatus:1,
        displayMode:0,
      }
      this.$refs[form].resetFields() 
    },

    clearnsonF(form){
      this.sonform={
        whetherUpdateImg:'n',
        name:'',
        url:'',
        router:'',
        description:'',
        generalStatus:1,
        displayMode:0,
        status:1,
        sort:0,
        type:1
      }
      this.$refs[form].resetFields()
    },
    search(){
      this.loadList(this.pageParams)
    }
  }
}
</script>

<style scoped>
.beforCon:before{
  content: '*';
  color: #F56C6C;
  position: absolute;
  left: -92px;
  top: 0px;
  z-index: 9;
}
.beforConwei:before{
  content: '*';
  color: #F56C6C;
  position: absolute;
  left: -105px;
  top: 0px;
  z-index: 9;
}
</style>
